export default {
  application: { 
    title: "Sirebel",
    description: "Test Instance"
  },
  cdn: {
    URL: "https://test.sirebel.com"
  },
  api: {
    URL: "https://api.test.sirebel.com/v1"
  },
  authentication: {
    AUTH_CLIENT: "459u2su355kc73ksk32jqut5ih"
  }
}